
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl"
]

export const localeLoaders = {
  "en": [{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json" /* webpackChunkName: "locale__data_www_mozaiek_nl_mozaiek_releases_343_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_2__64nuxt_43devtools_641_2_0__64unhead_43shared_641_9_7__64vue_43compiler_core_lifdvjnf2z6kn3zdjmnir6ogtq_node_modules__64bttr_devs_nuxt_layer_base_locales_en_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json" /* webpackChunkName: "locale__data_www_mozaiek_nl_mozaiek_releases_343_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_3_1__64nuxt_43devtools_641_2_0__64unhead_43shared_641_9_7__64vue_43compiler_cor_nd7goimyyfohgbos775jbwfzua_node_modules__64bttr_devs_nuxt_layer_sofie_locales_en_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json" /* webpackChunkName: "locale__data_www_mozaiek_nl_mozaiek_releases_343_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_auth_641_3_1__64nuxt_43devtools_641_2_0__64unhead_43shared_641_9_7__64vue_43compile_f4xjmhv2xqnoe2vqvmouekdcfa_node_modules__64bttr_devs_nuxt_layer_sofie_auth_locales_en_json" */), cache: true }],
  "nl": [{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json" /* webpackChunkName: "locale__data_www_mozaiek_nl_mozaiek_releases_343_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_base_641_0_2__64nuxt_43devtools_641_2_0__64unhead_43shared_641_9_7__64vue_43compiler_core_lifdvjnf2z6kn3zdjmnir6ogtq_node_modules__64bttr_devs_nuxt_layer_base_locales_nl_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json" /* webpackChunkName: "locale__data_www_mozaiek_nl_mozaiek_releases_343_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_641_3_1__64nuxt_43devtools_641_2_0__64unhead_43shared_641_9_7__64vue_43compiler_cor_nd7goimyyfohgbos775jbwfzua_node_modules__64bttr_devs_nuxt_layer_sofie_locales_nl_json" */), cache: true },
{ key: "../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json", load: () => import("../../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json" /* webpackChunkName: "locale__data_www_mozaiek_nl_mozaiek_releases_343_nuxt_node_modules__pnpm__64bttr_devs_43nuxt_layer_sofie_auth_641_3_1__64nuxt_43devtools_641_2_0__64unhead_43shared_641_9_7__64vue_43compile_f4xjmhv2xqnoe2vqvmouekdcfa_node_modules__64bttr_devs_nuxt_layer_sofie_auth_locales_nl_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "files": [
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json"
      ]
    },
    {
      "code": "nl",
      "files": [
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json",
        "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json"
      ]
    }
  ],
  "defaultLocale": "nl",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/en.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/en.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/en.json"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-core_lifdvjnf2z6kn3zdjmnir6ogtq/node_modules/@bttr-devs/nuxt-layer-base/locales/nl.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compiler-cor_nd7goimyyfohgbos775jbwfzua/node_modules/@bttr-devs/nuxt-layer-sofie/locales/nl.json"
      },
      {
        "path": "../node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.1_@nuxt+devtools@1.2.0_@unhead+shared@1.9.7_@vue+compile_f4xjmhv2xqnoe2vqvmouekdcfa/node_modules/@bttr-devs/nuxt-layer-sofie-auth/locales/nl.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
