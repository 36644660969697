import routerOptions0 from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/node_modules/.pnpm/nuxt@3.11.2_@unocss+reset@0.59.4_eslint@8.57.0_floating-vue@5.2.2_rollup@4.14.3_stylelint@16._6g3jdonuskwqzyxpkhs7ayby7m/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}