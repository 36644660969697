import { default as _iconsUFOArD0OzwMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/core/pages/_icons.vue?macro=true";
import { default as _91_46_46_46page_93NrphZVrLpiMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/[...page].vue?macro=true";
import { default as _91child_93rFvHpvIBi8Meta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/[child].vue?macro=true";
import { default as allei6VwyKxFxpMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index/alle.vue?macro=true";
import { default as indexM0aOQE6Wc5Meta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index/index.vue?macro=true";
import { default as kaart3dtu8AiawnMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index/kaart.vue?macro=true";
import { default as indexL0J8erpkqQMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index.vue?macro=true";
import { default as _91slug_93ETFUKB1Q4XMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug].vue?macro=true";
import { default as _91id_93R9HlyVtmcTMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/[date]/[id].vue?macro=true";
import { default as indexjaCsyBH2Y4Meta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/[date]/index.vue?macro=true";
import { default as _91date_93gm7Fnf4MM3Meta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/[date].vue?macro=true";
import { default as indexkS0I4Lr03SMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/index.vue?macro=true";
import { default as _91date_93lj6cW1CJbRMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/evenementen/[uuid]/[date].vue?macro=true";
import { default as indexmCO8tvtxUxMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/evenementen/index.vue?macro=true";
import { default as giftJVBMLqLB0NMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/geven/gift.vue?macro=true";
import { default as machtigingdRJuE4yHT1Meta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/geven/machtiging.vue?macro=true";
import { default as _91slug_93SweOjwtl1yMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/media/[slug].vue?macro=true";
import { default as archiefTAz21RMeZIMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/media/archief.vue?macro=true";
import { default as indexwJv4NfinqjMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/media/index.vue?macro=true";
import { default as _91slug_93z9BPVRdYmYMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/nieuws/[slug].vue?macro=true";
import { default as indexi7tXoWXi33Meta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/nieuws/index.vue?macro=true";
import { default as zoekenZ8rolc7hCHMeta } from "/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/zoeken.vue?macro=true";
export default [
  {
    name: _iconsUFOArD0OzwMeta?.name ?? "_icons",
    path: _iconsUFOArD0OzwMeta?.path ?? "/_icons",
    meta: _iconsUFOArD0OzwMeta || {},
    alias: _iconsUFOArD0OzwMeta?.alias || [],
    redirect: _iconsUFOArD0OzwMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/core/pages/_icons.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46page_93NrphZVrLpiMeta?.name ?? "page",
    path: _91_46_46_46page_93NrphZVrLpiMeta?.path ?? "/:page(.*)*",
    meta: _91_46_46_46page_93NrphZVrLpiMeta || {},
    alias: _91_46_46_46page_93NrphZVrLpiMeta?.alias || [],
    redirect: _91_46_46_46page_93NrphZVrLpiMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ETFUKB1Q4XMeta?.name ?? undefined,
    path: _91slug_93ETFUKB1Q4XMeta?.path ?? "/communities/:slug()",
    meta: _91slug_93ETFUKB1Q4XMeta || {},
    alias: _91slug_93ETFUKB1Q4XMeta?.alias || [],
    redirect: _91slug_93ETFUKB1Q4XMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug].vue").then(m => m.default || m),
    children: [
  {
    name: _91child_93rFvHpvIBi8Meta?.name ?? "communities-slug-child",
    path: _91child_93rFvHpvIBi8Meta?.path ?? ":child()",
    meta: _91child_93rFvHpvIBi8Meta || {},
    alias: _91child_93rFvHpvIBi8Meta?.alias || [],
    redirect: _91child_93rFvHpvIBi8Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/[child].vue").then(m => m.default || m)
  },
  {
    name: indexL0J8erpkqQMeta?.name ?? undefined,
    path: indexL0J8erpkqQMeta?.path ?? "",
    meta: indexL0J8erpkqQMeta || {},
    alias: indexL0J8erpkqQMeta?.alias || [],
    redirect: indexL0J8erpkqQMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index.vue").then(m => m.default || m),
    children: [
  {
    name: allei6VwyKxFxpMeta?.name ?? "communities-slug-index-alle",
    path: allei6VwyKxFxpMeta?.path ?? "alle",
    meta: allei6VwyKxFxpMeta || {},
    alias: allei6VwyKxFxpMeta?.alias || [],
    redirect: allei6VwyKxFxpMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index/alle.vue").then(m => m.default || m)
  },
  {
    name: indexM0aOQE6Wc5Meta?.name ?? "communities-slug-index",
    path: indexM0aOQE6Wc5Meta?.path ?? "",
    meta: indexM0aOQE6Wc5Meta || {},
    alias: indexM0aOQE6Wc5Meta?.alias || [],
    redirect: indexM0aOQE6Wc5Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index/index.vue").then(m => m.default || m)
  },
  {
    name: kaart3dtu8AiawnMeta?.name ?? "communities-slug-index-kaart",
    path: kaart3dtu8AiawnMeta?.path ?? "kaart",
    meta: kaart3dtu8AiawnMeta || {},
    alias: kaart3dtu8AiawnMeta?.alias || [],
    redirect: kaart3dtu8AiawnMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/communities/[slug]/index/kaart.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91date_93gm7Fnf4MM3Meta?.name ?? undefined,
    path: _91date_93gm7Fnf4MM3Meta?.path ?? "/doopgetuigenissen/:date()",
    meta: _91date_93gm7Fnf4MM3Meta || {},
    alias: _91date_93gm7Fnf4MM3Meta?.alias || [],
    redirect: _91date_93gm7Fnf4MM3Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/[date].vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93R9HlyVtmcTMeta?.name ?? "doopgetuigenissen-date-id",
    path: _91id_93R9HlyVtmcTMeta?.path ?? ":id()",
    meta: _91id_93R9HlyVtmcTMeta || {},
    alias: _91id_93R9HlyVtmcTMeta?.alias || [],
    redirect: _91id_93R9HlyVtmcTMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/[date]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjaCsyBH2Y4Meta?.name ?? "doopgetuigenissen-date",
    path: indexjaCsyBH2Y4Meta?.path ?? "",
    meta: indexjaCsyBH2Y4Meta || {},
    alias: indexjaCsyBH2Y4Meta?.alias || [],
    redirect: indexjaCsyBH2Y4Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/[date]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexkS0I4Lr03SMeta?.name ?? "doopgetuigenissen",
    path: indexkS0I4Lr03SMeta?.path ?? "/doopgetuigenissen",
    meta: indexkS0I4Lr03SMeta || {},
    alias: indexkS0I4Lr03SMeta?.alias || [],
    redirect: indexkS0I4Lr03SMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/doopgetuigenissen/index.vue").then(m => m.default || m)
  },
  {
    name: _91date_93lj6cW1CJbRMeta?.name ?? "evenementen-uuid-date",
    path: _91date_93lj6cW1CJbRMeta?.path ?? "/evenementen/:uuid()/:date()",
    meta: _91date_93lj6cW1CJbRMeta || {},
    alias: _91date_93lj6cW1CJbRMeta?.alias || [],
    redirect: _91date_93lj6cW1CJbRMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/evenementen/[uuid]/[date].vue").then(m => m.default || m)
  },
  {
    name: indexmCO8tvtxUxMeta?.name ?? "evenementen",
    path: indexmCO8tvtxUxMeta?.path ?? "/evenementen",
    meta: indexmCO8tvtxUxMeta || {},
    alias: indexmCO8tvtxUxMeta?.alias || [],
    redirect: indexmCO8tvtxUxMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/evenementen/index.vue").then(m => m.default || m)
  },
  {
    name: giftJVBMLqLB0NMeta?.name ?? "geven-gift",
    path: giftJVBMLqLB0NMeta?.path ?? "/geven/gift",
    meta: giftJVBMLqLB0NMeta || {},
    alias: giftJVBMLqLB0NMeta?.alias || [],
    redirect: giftJVBMLqLB0NMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/geven/gift.vue").then(m => m.default || m)
  },
  {
    name: machtigingdRJuE4yHT1Meta?.name ?? "geven-machtiging",
    path: machtigingdRJuE4yHT1Meta?.path ?? "/geven/machtiging",
    meta: machtigingdRJuE4yHT1Meta || {},
    alias: machtigingdRJuE4yHT1Meta?.alias || [],
    redirect: machtigingdRJuE4yHT1Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/geven/machtiging.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93SweOjwtl1yMeta?.name ?? "media-slug",
    path: _91slug_93SweOjwtl1yMeta?.path ?? "/media/:slug()",
    meta: _91slug_93SweOjwtl1yMeta || {},
    alias: _91slug_93SweOjwtl1yMeta?.alias || [],
    redirect: _91slug_93SweOjwtl1yMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/media/[slug].vue").then(m => m.default || m)
  },
  {
    name: archiefTAz21RMeZIMeta?.name ?? "media-archief",
    path: archiefTAz21RMeZIMeta?.path ?? "/media/archief",
    meta: archiefTAz21RMeZIMeta || {},
    alias: archiefTAz21RMeZIMeta?.alias || [],
    redirect: archiefTAz21RMeZIMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/media/archief.vue").then(m => m.default || m)
  },
  {
    name: indexwJv4NfinqjMeta?.name ?? "media",
    path: indexwJv4NfinqjMeta?.path ?? "/media",
    meta: indexwJv4NfinqjMeta || {},
    alias: indexwJv4NfinqjMeta?.alias || [],
    redirect: indexwJv4NfinqjMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93z9BPVRdYmYMeta?.name ?? "nieuws-slug",
    path: _91slug_93z9BPVRdYmYMeta?.path ?? "/nieuws/:slug()",
    meta: _91slug_93z9BPVRdYmYMeta || {},
    alias: _91slug_93z9BPVRdYmYMeta?.alias || [],
    redirect: _91slug_93z9BPVRdYmYMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/nieuws/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexi7tXoWXi33Meta?.name ?? "nieuws",
    path: indexi7tXoWXi33Meta?.path ?? "/nieuws",
    meta: indexi7tXoWXi33Meta || {},
    alias: indexi7tXoWXi33Meta?.alias || [],
    redirect: indexi7tXoWXi33Meta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/nieuws/index.vue").then(m => m.default || m)
  },
  {
    name: zoekenZ8rolc7hCHMeta?.name ?? "zoeken",
    path: zoekenZ8rolc7hCHMeta?.path ?? "/zoeken",
    meta: zoekenZ8rolc7hCHMeta || {},
    alias: zoekenZ8rolc7hCHMeta?.alias || [],
    redirect: zoekenZ8rolc7hCHMeta?.redirect,
    component: () => import("/data/www/mozaiek.nl/mozaiek/releases/343/nuxt/website/pages/zoeken.vue").then(m => m.default || m)
  }
]